import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import { useHistory } from 'react-router-dom';
import { toggleSearch as toggleSearchAction } from '../actions/app';
import { API_PREFIX } from '../constants';
import { formatIntlMessage, request, getLocaleKey, escape, errorHandler } from '../utils';
import './Search.less';

const getPath = (item) => {
  switch (item.type) {
    case 'page':
      return item.data?.path;
    case 'news':
      return `/news/${item.data?.id}`;
    case 'story':
      return `/stories/${item.data?.id}`;
    default:
      return '/';
  }
};

const NO_RESULT_DATA = [
  {
    type: 'page',
    data: {
      id: 1,
      titleCn: '联系我们',
      titleEn: 'Contact',
      path: '/contact',
      subTitleCn: '媒体部 | 人力资源 | 客户服务 | 商务合作',
      subTitleEn: '媒体部 | 人力资源 | 客户服务 | 商务合作',
      // descriptionCn: 'mkt@cj-mh.com ... hr@cj-mh.com ... customerservice@cj-mh.com ... bd@cj-mh.com ... 徕希心理企业微信 ... 徕希心理微信公众号 ... mkt@cj-mh.com ... hr@cj-mh.com ... ',
      // descriptionEn: 'Nov 18, 2009 ... The main material is 2x4 or 1x4 construction studs that can be seen everywhere in this world. I thought it would be wise to make something ...',
    },
  },
  {
    type: 'page',
    data: {
      id: 2,
      titleCn: '徕希产品',
      path: '/products',
      titleEn: 'CJMH Product',
      subTitleCn: '学生端 | 学校端',
      subTitleEn: 'Students | Schools',
      descriptionCn: '为学生、家庭、学校、心理咨询师提供心理健康测评、心理档案管理、数据统计分析、日常心理教师工作管理、心理咨询预约记录等功能的全方位管理系统 ...',
      descriptionEn:
        'CJ Mental Health provides high quality system with joint functions such as mental health evaluation, psychological profile management, statistic data analysis, school counselor’s daily working management ...',
    },
  },
  {
    type: 'page',
    data: {
      id: 3,
      titleCn: '关于我们',
      path: '/about',
      titleEn: 'About Us',
      subTitleCn: '使命与愿景 | 团队概览',
      subTitleEn: 'Mission & Vision | Team',
      descriptionCn: '在中国，每年约有10万青少年死于自杀，约5000万青少年经历着各种情绪障碍和行为问题的困扰—这个数字的背后代表着每五名青少年中便有一名孩子有心理健康问题...',
      descriptionEn: 'There are around 100 thousand teenagers committing suicide in China each year, fifty million adolescents experiencing either mood disorders or behavior problems ...',
    },
  },
];

const SIZE = 30;

function Search() {
  const history = useHistory();
  const { lang, showSearch } = useSelector((state) => ({
    lang: state.app.lang,
    showSearch: state.app.showSearch,
  }));
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const [searchText, setSearchText] = useState('');
  const [data, setData] = useState([]);
  const [showEmpty, setShowEmpty] = useState(false);
  const toggleSearch = () => dispatch(toggleSearchAction());

  const loadData = async (word) => {
    const realWord = word?.trim();
    setSearchText(realWord);
    // rest
    if (!realWord) {
      setShowEmpty(false);
      setData([]);
      return;
    }
    try {
      const result = await request(`${API_PREFIX}/search?type=all&language=${lang}&text=${realWord}&size=${SIZE}&page=1`, {
        method: 'GET',
      });
      const nextData = [...result.data];
      if (nextData.length === 0) {
        setShowEmpty(true);
        setData(() => {
          return [...NO_RESULT_DATA];
        });
        return;
      }
      setShowEmpty(false);
      setData(() => {
        return nextData;
      });
    } catch (error) {
      errorHandler({ error, showErrorNotification: false });
      setShowEmpty(true);
      setData(() => {
        return [...NO_RESULT_DATA];
      });
    }
  };

  const debounceSearch = debounce(loadData, 300);

  useEffect(() => {
    if (inputRef?.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <div className={`SearchContainer ${showSearch ? 'Show' : 'Hidden'}`}>
      <div className={'SearchWrapper'}>
        <div className={'SearchInput'}>
          <svg className={'SearchIcon'} width="13px" height="14px" viewBox="0 0 13 14" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g id="页面-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g id="首页备份-18-copy-3备份-13" transform="translate(-275.000000, -32.000000)">
                <g id="编组" transform="translate(275.000000, 32.000000)">
                  <g>
                    <circle id="椭圆形" stroke="#6F6F6F" strokeWidth="0.8" cx="6" cy="6" r="5.6" />
                    <path
                      d="M9.39746094,10.4262695 C8.82775879,9.82531738 12.6147461,13.65 12.6147461,13.65 C12.6147461,13.65 8.82775879,9.82531738 9.39746094,10.4262695 Z"
                      id="路径-7"
                      stroke="#6F6F6F"
                      strokeWidth="0.8"
                    />
                  </g>
                </g>
              </g>
            </g>
          </svg>
          <input
            autoFocus={true}
            type={'search'}
            onChange={(e) => {
              // loadData(e.target.value);
              debounceSearch(e.target.value);
            }}
            ref={inputRef}
            placeholder={formatIntlMessage('component_search_placeholder')}
          />
          <svg width="13px" height="13px" viewBox="0 0 13 13" version="1.1" xmlns="http://www.w3.org/2000/svg" className={'CloseIcon'} onClick={toggleSearch}>
            <g id="页面-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g id="首页备份-18-copy-3备份-13" transform="translate(-957.000000, -33.000000)" stroke="#FFFFFF" strokeWidth="0.5">
                <g id="编组-4" transform="translate(957.000000, 34.000000)">
                  <line x1="1" y1="0" x2="12" y2="11" id="路径-8" />
                  <line x1="11.6554102" y1="0.00532945736" x2="0.24798103" y2="10.7784541" id="路径-8备份" />
                </g>
              </g>
            </g>
          </svg>
        </div>
        <div className={'SearchGap'}>
          <div className={'Divider'} />
        </div>
        {showEmpty && (
          <div className="SearchNoData">
            <div className="SearchNoDataImg" />
            <div className="SearchNoDataMain">
              <div className="SearchNoDataTitle">{`${formatIntlMessage('component_search_no_data_title')} ${searchText}${lang === 'cn' ? '。' : '.'}`}</div>
              <div className="SearchNoDataTip">{formatIntlMessage('component_search_no_data_tip')}</div>
            </div>
          </div>
        )}
        <div className={'SearchContent'}>
          {data.map((item) => (
            <div
              className={'SearchResultItem'}
              key={`${item.type}_${item.data?.id}`}
              onClick={() => {
                toggleSearch();
                history.push(getPath(item));
              }}
            >
              <div type="link" className={'Title'}>
                {item?.data[getLocaleKey('title')]}
              </div>
              <div className={'Url'}>
                <div type="link">{item.data?.[getLocaleKey('subTitle')] || `cjmh.com${getPath(item)}`}</div>
              </div>
              <div className={'Desc'}>
                <div className={'DescText'} dangerouslySetInnerHTML={{ __html: escape(item.data?.[getLocaleKey('content')] || item.data?.[getLocaleKey('description')]) }} />
                <div className={'Divider'} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Search;
