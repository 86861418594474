import React from 'react';
import './Header.css';
import { toggleSearch, toggleSideMenu } from '../actions/app';
import { connect } from 'react-redux';
import { history } from '../store';
import { setLocale, formatIntlMessage } from '../utils';

function Header({ pathname, toggleSearch, logoColor, lang }) {
  return (
    <div className={'Header HeaderRight'}>
      <div>
        <div className={'MenuContainer'}>
          <svg
            width="13px"
            height="14px"
            viewBox="0 0 13 14"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => {
              toggleSearch();
            }}
          >
            <g stroke="#6F6F6F" strokeWidth="1" fill="none" fillRule="evenodd">
              <g transform="translate(-1035.000000, -27.000000)" strokeWidth="0.8">
                <g transform="translate(53.947152, 23.179999)">
                  <g transform="translate(981.052848, 0.820001)">
                    <g transform="translate(0.000000, 3.000000)">
                      <circle cx="6" cy="6" r="5.6" />
                      <path d="M9.39746094,10.4262695 C8.82775879,9.82531738 12.6147461,13.65 12.6147461,13.65 C12.6147461,13.65 8.82775879,9.82531738 9.39746094,10.4262695 Z" />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </svg>
          <span
            onClick={() => {
              history.push('/products');
            }}
            className={pathname === '/products' ? `Active ${logoColor === 'dark' ? 'Light' : 'Dark'}` : ''}
            // style={{
            //   // color: pathname === '/products' ? '#0897E0' : 'inherit',
            //   color: pathname === '/products' ? 'black' : 'inherit',
            // }}
          >
            {formatIntlMessage('nav_products')}
          </span>
          <span
            className={pathname === '/contact-us' ? 'Active' : ''}
            onClick={() => {
              history.push('/contact-us');
            }}
          >
            {formatIntlMessage('nav_contact')}
          </span>
          <svg width="1px" height="11px" viewBox="0 0 1 14" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g id="页面-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g id="主页备份-3" transform="translate(-1192.000000, -26.000000)" stroke="#6F6F6F" strokeWidth="0.8">
                <g id="Group-2" transform="translate(53.947152, 23.179999)">
                  <g id="Group" transform="translate(981.052848, 0.820001)">
                    <line x1="157.5" y1="2" x2="157.5" y2="15.8" id="路径-6" />
                  </g>
                </g>
              </g>
            </g>
          </svg>
          <span
            // className={pathname === '/login' ? 'Active' : ''}
            onClick={() => {
              window.location.href = 'https://app.cj-mh.com/school/#/passport/login';
              // history.push('/login');
            }}
          >
            {formatIntlMessage('nav_signin')}
          </span>
          <span onClick={setLocale} className={'lang langFirst'}>
            {lang === 'en' ? '中文' : 'EN'}
          </span>
        </div>
        <div className={'MenuLogo'}>
          <svg width="17px" height="13px" viewBox="0 0 17 13" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g id="页面-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="square">
              <g id="主页备份-2" transform="translate(-55.000000, -89.000000)" stroke="#000000">
                <g id="Group" transform="translate(56.000000, 89.000000)">
                  <line x1="-4.88448567e-13" y1="0.5" x2="15.1428571" y2="0.5" id="直线" />
                  <line x1="-4.51136896e-13" y1="6.5" x2="15.1428571" y2="6.5" id="直线备份" />
                  <line x1="-4.51136896e-13" y1="12.5" x2="15.1428571" y2="12.5" id="直线备份-2" />
                </g>
              </g>
            </g>
          </svg>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    pathname: state.router.location.pathname,
    showSideMenu: state.app.showSideMenu,
    lang: state.app.lang,
    logoColor: state.app.logoColor,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleSideMenu: () => dispatch(toggleSideMenu()),
    toggleSearch: () => dispatch(toggleSearch()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
