import React, { useEffect, useState } from 'react';
import './GradientBackground.css';

function GradientBackground() {
  const [bgIndex, setBgIndex] = useState(0);
  useEffect(() => {
    let i = 0;
    const t = setInterval(() => {
      i = i + 1;
      setBgIndex(i);
    }, 4000);
    return () => {
      clearInterval(t);
    };
  }, []);
  return (
    <div className={`GradientBackground Gradient${bgIndex % 4}`}>
      <div className={'Bg0'} />
      <div className={'Bg1'} />
      <div className={'Bg2'} />
      <div className={'Bg3'} />
      <div className={'Bg4'} />
      <div className={'Bg5'} />
      <div className={'Bg6'} />
    </div>
  );
}

export default GradientBackground;
