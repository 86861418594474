/**
 * 请求任务队列
 */

//  加入/更新任务
export const setRequestTask = (key, val) => {
  if (!key) {
    return;
  }
  if (!window.__REQUEST_TASKS) {
    window.__REQUEST_TASKS = {
      [key]: val,
    };
    return;
  }
  window.__REQUEST_TASKS[key] = val;
};

// 获取任务
export const getRequestTask = (key) => {
  if (!key) {
    return undefined;
  }
  const tasks = window.__REQUEST_TASKS || {};
  return tasks[key];
};

// 移除任务
export const removeRequestTask = (key) => {
  if (!key) {
    return;
  }
  const tasks = window.__REQUEST_TASKS || {};
  delete tasks[key];
};

// 中止http请求
export const requestAbort = (key) => {
  const requestTask = getRequestTask(key);
  if (requestTask) {
    requestTask.abort();
    removeRequestTask(key);
  }
};

// 批量中止http请求
export const requestAbortByPrefix = (prefix) => {
  const matchTasks = Object.keys(window.__REQUEST_TASKS).filter((key) => new RegExp(`^${prefix}`).test(key));
  matchTasks.forEach((task) => {
    requestAbort(task);
  });
};
