import React from 'react';

export default function (props) {
  // return <svg width="9px" height="15px" viewBox="0 0 9 15" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
  //   <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
  //     <g transform="translate(-55.000000, -97.000000)" stroke="#6F6F6F">
  //       <polyline points="63.2176201 98 55.2176201 104.5 63.2176201 111"/>
  //     </g>
  //   </g>
  // </svg>
  return (
    <svg width="16px" height="13px" viewBox="0 0 16 13" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g stroke="#6F6F6F">
          <polyline transform="translate(8.000000, 6.500000) rotate(270.000000) translate(-8.000000, -6.500000) " points="12 0 4 6.5 12 13" />
          <rect stroke="none" fillOpacity="0" fill="#D8D8D8" x="0" y="0" width="16" height="13" />
        </g>
      </g>
    </svg>
  );
}
