import store from '../store';

const LOCALE_STORAGE_KEY = '__CJMH_LOCALE';

export const getHistoryQuery = () => {
  return window.location.search
    .replace(/^\?/, '')
    .split('&')
    .reduce((prev, curr) => {
      const [k, v] = curr.split('=');
      return { ...prev, ...(!!k ? { [k]: v } : {}) };
    }, {});
};

export const getLocale = () => {
  // const { language: urlLocale } = getHistoryQuery();
  // if (urlLocale) {
  //   const realLocale = ['en', 'cn'].includes(urlLocale) ? urlLocale : 'cn';
  //   return realLocale;
  // }
  const storageLocale = window.localStorage.getItem(LOCALE_STORAGE_KEY);
  return storageLocale === 'en' ? 'en' : 'cn';
};

export const getLocaleKey = (name) => {
  const { app } = store.getState();
  return `${name}${app.lang === 'en' ? 'En' : 'Cn'}`;
};

export const getLocalItemValue = (data, key) => {
  return data[getLocaleKey(key)];
};

export const setLocale = () => {
  const currLocale = getLocale();
  const nextLocale = currLocale === 'en' ? 'cn' : 'en';
  // 存入缓存
  window.localStorage.setItem(LOCALE_STORAGE_KEY, nextLocale);
  // 刷新页面
  window.location.reload();
};

const LOCALE_DATE = {
  cn: {
    common_loading: '正在加载',
    common_load_more: '加载更多',
    common_submit: '提交',
    common_back: '返回',
    common_article_not_found: '文章不存在或已被删除',
    menu_products: '徕希产品',
    menu_about: '关于我们',
    menu_about_mission_VISION: '使命与愿景',
    menu_about_team: '团队概览',
    menu_team: '团队',
    menu_community: '徕希社区',
    menu_community_research: '科研计划',
    menu_community_story: '学校故事',
    menu_contact: '加入我们',
    menu_news: '新闻',
    menu_partners: '合作伙伴',
    nav_products: '了解产品',
    nav_contact: '联系我们',
    nav_signin: '登录',
    component_share_name: '分享：',
    component_share_wechat_name: '微信',
    component_share_on_chat: '分享至好友',
    component_share_on_moments: '分享至朋友圈',
    component_search_placeholder: '搜索...',
    component_search_no_data_title: '对不起...没有找到',
    component_search_no_data_tip: '您可以改进关键词继续搜索或者您也许对以下内容感兴趣：',
    page_home_focus_one_context: '在中国，每五名青少年中便有一名有心理健康问题。50%的精神心理疾病始于14岁，大多数都处于未发现、未治疗的状态。',
    page_home_focus_one_source: '世界卫生组织',
    page_home_focus_two_context: '自杀是全球青少年第二大死因。我国每年约有10万青少年死于自杀。 约每6名中学生中，就有1人有过自杀意念，每14名中学生中就有1人制定过自杀计划。',
    page_home_focus_two_source: '《中国儿童的生存与发展：数据与分析》及《2000～2013年中国中学生自杀相关行为报告》',
    page_home_focus_three_context: '中国人口14亿，仅有不足四万人从事心理咨询专职或兼职工作。根据WHO指导意见，我国心理咨询师缺口多达130万人。',
    page_home_focus_four_context: '中国在校中小学生约为2.1亿人，而心理健康服务人员与在校人数比例约1:7125',
    page_home_focus_four_source: '教育部《2019年全国教育事业发展统计公报》',
    page_contact_us: '联系我们!',
    page_contact_recruitment_info: '招聘信息',
    page_contact_recruitment_opportunity: '加入我们，你将有机会：',
    page_contact_recruitment_hope: '我们希望你：',
    page_contact_recruitment_apply: '应聘申请',
    page_contact_recruitment_download: '下载应聘申请',
    page_contact_marketing_department: '媒体部',
    page_contact_human_resources: '人力资源',
    page_contact_customer_service: '客户服务',
    page_contact_business_cooperation: '商务合作',
    page_contact_qywx_1: '徕希心理',
    page_contact_qywx_2: '企业微信',
    page_contact_wx_1: '徕希心理',
    page_contact_wx_2: '微信公众号',
    page_news_news: '新闻',
    page_news_more: '更多新闻',
    page_team_team: '我们的团队',
    page_community_community: '徕希社区',
    page_community_desc:
      '我们致力于打造一个开放多元，充满活力，创造力以及极具归属感的社区，联接对心理教育充满热忱的学术人才，深度交流、学习、分享与合作。在这里，你有机会展示自己的学术成果，也可以通过聆听他人的研究故事从而实现自我成长，或是加入我们的科研计划，共同推动心理教育的发展。',
    page_community_focus_1: '给予，收获，分享。',
    page_community_focus_2: '我们与你同行，共同成长。',
    page_community_research: '科研计划',
    page_community_research_desc_1:
      '近年来，有关青少年心理健康状况突发事件频发发生，为各个学校青少年心理健康问题拉响了警钟，而造成心理健康问题的原因也逐步呈多样化。在缺乏专业支持时，学生很容易错过最佳的干预时机，从而发生无法挽回的悲剧。',
    page_community_research_desc_2:
      '为帮助学校预防及改善在校生的心理问题，赋能学校心理健康教育工作，徕希联手北大心理学博士&青少年心理危机干预专家徐凯文教授长期发起青少年心理健康评估系统及改善方案横向课题，邀请全国所有体系的中小学院校加入中国青少年心理健康守护行动，共建心理健康教育体系。',
    page_community_research_focus: '我们为所有加入课题计划的学校提供全校学生心理健康专业测评，以及全面的校级健康状况专业分析报告。',
    page_community_down_pdf_know_more: '下载PDF,了解更多',
    page_community_story: '学校故事',
    page_community_story_about_1: '对于青少年的成长，学校无疑是个至关重要的地方。',
    page_community_story_about_2: '守护青少年心理健康，他们在行动。',
    page_partners_partners: '合作院校',
    page_partners_org: '权威机构',
    page_contactus_name: '姓名',
    page_contactus_career: '职务',
    page_contactus_phone: '电话',
    page_contactus_email: '邮箱',
    page_contactus_organization: '机构性质',
    page_contact_purpose: '希望了解',
    page_contactus_career_school_counselor: '学校心理老师',
    page_contactus_career_school_official: '学校负责人',
    page_contactus_career_psychological_counselor: '专业心理咨询师',
    page_contactus_career_others: '其他',
    page_contactus_organization_placeholder: '学校/机构/其他',
    page_contactus_purpose_business_cooperation: '商务合作',
    page_contactus_purpose_service_procurement: '服务采购',
    page_contactus_purpose_others: '其他',
    page_contactus_name_error_text: '请输入姓名',
    page_contactus_career_error_text_1: '请选择职务',
    page_contactus_career_error_text_2: '请输入职务',
    page_contactus_organization_error: '请输入机构性质',
    page_contactus_phone_error_text_1: '请输入手机号码',
    page_contactus_phone_error_text_2: '请输入格式正确的手机号码！',
    page_contactus_email_error_text: '请输入格式正确的邮箱！',
    page_contact_purpose_error_text_1: '请选择目的',
    page_contact_purpose_error_text_2: '请输入希望了解的内容',
    page_contactus_submit_success: '完成提交',
  },
  en: {
    common_loading: 'Loading...',
    common_load_more: 'More',
    common_submit: 'Submit',
    common_back: 'Back',
    common_article_not_found: 'The article does not exist or has been deleted',
    menu_products: 'Products',
    menu_about: 'About Us',
    menu_about_mission_VISION: 'Mission & Vision',
    menu_about_team: 'Team Overview',
    menu_team: 'Team',
    menu_community: 'Community',
    menu_community_research: 'Project',
    menu_community_story: 'School’s Story',
    menu_contact: 'Contact',
    menu_news: 'News',
    menu_partners: 'Partners',
    nav_products: 'Products',
    nav_contact: 'Contact',
    nav_signin: 'Sign in',
    component_share_name: 'Share: ',
    component_share_wechat_name: 'WeChat',
    component_share_on_chat: 'Share on Chat',
    component_share_on_moments: 'Share on Moments',
    component_search_placeholder: 'Search...',
    component_search_no_data_title: 'Sorry...we couldn’t find any result matching ',
    component_search_no_data_tip: 'you could try different keywords or you may be interested in the following: ',
    page_home_focus_one_context:
      'There is at least one child in every five teenagers suffering from mental health problems in China, of which 50% of mental health problems begin at the age of 14 and remains either undiscovered or untreated.',
    page_home_focus_one_source: 'World Health Organization',
    page_home_focus_two_context:
      'Commit suicide has become the second leading case of global teenagers’ death. There are around 100 thousand teenagers committing suicide in China each year. One of six has suicidal thought, and one of fourteen plans for suicide.',
    page_home_focus_two_source: `Children's Survial and Development in China:Data and Analysis,  Prevalence of suicide related bahaviors among middle school students in China from 2000 to 2013`,
    page_home_focus_three_context:
      'There are 1.4 billion Chinese population, and fewer than 40000 people worked in full time or part time psychological counseling. According to the suggestion of WHO, the demand gap of psychological counselor reaches for 1.3 million.',
    page_home_focus_four_context:
      'There are 210 million Chinese elementary or middle school students, and the rate between people worked in mental health services and students is approximately 1:7125.',
    page_home_focus_four_source: 'Statistical Bulletin on the Development of National Education in 2019',
    page_contact_us: 'CONTACT US',
    page_contact_recruitment_info: 'RECRUITMENT INFORMATION',
    page_contact_recruitment_apply: 'Employment Application',
    page_contact_recruitment_download: 'Download Application Form',
    page_contact_recruitment_opportunity: 'Join us and you will have the opportunity to:',
    page_contact_recruitment_hope: 'We hope you are:',
    page_contact_marketing_department: 'Marketing Department',
    page_contact_human_resources: 'Human Resources',
    page_contact_customer_service: 'Customer Service',
    page_contact_business_cooperation: 'Business Cooperation',
    page_contact_qywx_1: 'Enterprise',
    page_contact_qywx_2: 'WeChat',
    page_contact_wx_1: 'WeChat',
    page_contact_wx_2: 'Official Account',
    page_news_news: 'NEWS',
    page_news_more: 'MORE',
    page_team_team: 'OUR TEAM',
    page_community_community: 'OUR COMMUNITY',
    page_community_desc:
      'We committed ourselves in establishing a diverse, energetic, creative, and affiliative community, connecting, communicating, studying, sharing, and collaborating with professionals who devoted their enthusiasm in the domain of psychological education. In our community, participants may exhibit academic achievements, exchanging research experience and developing potential goal. Participants may also join in our scientific effort and promote mental support system together.',
    page_community_focus_1: 'Give, Gain, and Share.',
    page_community_focus_2: 'We will develop and grow together.',
    page_community_research: 'PROJECTS',
    page_community_research_desc_1:
      'In recent years, the increasing trend of teenager’s mental health crisis rings an alarm to student’s mental health problems, and the potential factors triggering these problems become even more complicated. With a scarcity of professional support, the best prevention time could be missed which resulted in the irretrievable tragedy. ',
    page_community_research_desc_2:
      'Engaging in mitigating the mental health problems and empowering school counselor’s psychological education work, CJ Mental Health collaborates with Dr. Kai-wen Xu, a well-known clinical psychologist and teenager’s crisis prevention specialist, to launch a long-term researching topic about teenager’s mental health assessment system, inviting all elementaries and middle schools to join in Chinese teenagers’ mental health protect action.',
    page_community_research_focus: 'We offer comprehensive and professional school-level mental health analysis report and students’ status evaluation for school joining in our planning project.',
    page_community_down_pdf_know_more: 'Download PDF and Learn More',
    page_community_story: 'SCHOOL’S STORY',
    page_community_story_about_1: 'School environment is undoubtedly an important place to support teenagers’ growth.',
    page_community_story_about_2: 'Here they are to put enthusiasm into action.',
    page_partners_partners: 'COOPERATION SCHOOLS',
    page_partners_org: 'AUTHORITARIAN COOPERATION',
    page_contactus_name: 'Name',
    page_contactus_career: 'Career',
    page_contactus_phone: 'Phone',
    page_contactus_email: 'Mail',
    page_contactus_organization: 'Organization',
    page_contact_purpose: 'My Prospect to Know…',
    page_contactus_career_school_counselor: 'School Counselor',
    page_contactus_career_school_official: 'School Official',
    page_contactus_career_psychological_counselor: 'Psychological Counselor',
    page_contactus_career_others: 'Others',
    page_contactus_organization_placeholder: 'School/Institution/Others',
    page_contactus_purpose_business_cooperation: 'Business Cooperation',
    page_contactus_purpose_service_procurement: 'Service Procurement',
    page_contactus_purpose_others: 'Others',
    page_contactus_name_error_text: '请输入姓名',
    page_contactus_career_error_text_1: '请选择职务',
    page_contactus_career_error_text_2: '请输入职务',
    page_contactus_organization_error: '请输入机构性质',
    page_contactus_phone_error_text_1: '请输入手机号码',
    page_contactus_phone_error_text_2: '请输入格式正确的手机号码！',
    page_contactus_email_error_text: '请输入格式正确的邮箱！',
    page_contact_purpose_error_text_1: '请选择目的',
    page_contact_purpose_error_text_2: '请输入希望了解的内容',
    page_contactus_submit_success: 'Submit Complete',
  },
};

export const formatIntlMessage = (id) => {
  const { app } = store.getState();
  return LOCALE_DATE[app.lang]?.[id];
};
