import { SIDE_MENU_STATUS_KEY } from '../constants';

export const TOGGLE_SIDE_MENU = 'TOGGLE_SIDE_MENU';
export const SET_SIDE_MENU = 'SET_SIDE_MENU';
export const TOGGLE_SEARCH = 'TOGGLE_SEARCH';
export const SET_LOGO_COLOR = 'SET_LOGO_COLOR';
export const SET_LANG = 'SET_LANG';

export function setLogoColor(color) {
  return (dispatch) => {
    dispatch({ type: SET_LOGO_COLOR, payload: color });
  };
}

export function setLang(lang) {
  return (dispatch) => {
    dispatch({ type: SET_LANG, payload: lang });
  };
}

export function toggleSideMenu() {
  return (dispatch, getState) => {
    const {
      app: { showSideMenu },
    } = getState();
    dispatch(setSideMenu(!showSideMenu));
  };
}

export function setSideMenu(visible) {
  return (dispatch) => {
    const storageStr = visible ? '1' : '0';
    window.localStorage.setItem(SIDE_MENU_STATUS_KEY, storageStr);
    dispatch({ type: SET_SIDE_MENU, payload: visible });
  };
}

export function toggleSearch() {
  return (dispatch) => {
    dispatch({ type: TOGGLE_SEARCH });
  };
}
