import React, { useEffect, useRef, useState } from 'react';
// import Home from './pages/Home';
import './App.less';
import HeaderLeft from './components/HeaderLeft';
import HeaderRight from './components/HeaderRight';
import SideBar from './components/SideBar';
import { setLang as setLangAction } from './actions/app';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router';
import loadable from '@loadable/component';
import Background from './components/Background';
import Search from './components/Search';
import { getLocale } from './utils';

const Home = loadable(() => import('./pages/Home'));
const About = loadable(() => import('./pages/About'));
const Team = loadable(() => import('./pages/Team'));
const Community = loadable(() => import('./pages/Community'));
const Contact = loadable(() => import('./pages/Contact'));
const News = loadable(() => import('./pages/News'));
const ContactUs = loadable(() => import('./pages/ContactUs'));
const Story = loadable(() => import('./pages/Story'));
const NewsDetails = loadable(() => import('./pages/NewsDetails'));
const Products = loadable(() => import('./pages/Products'));
const Partners = loadable(() => import('./pages/Partners'));

function App({ showSearch, pathname, logoColor, setLang }) {
  const [loading, setLoading] = useState(true);
  const [showLogin, setShowLogin] = useState(false);
  const [showContact, setShowContact] = useState(false);
  const contentRef = useRef(null);

  useEffect(() => {
    if (contentRef && contentRef.current) {
      contentRef.current?.scrollTo(0, 0);
    }
  }, [pathname]);

  useEffect(() => {
    const lang = getLocale();
    if (lang === 'en') {
      document.body.classList.add('v-en');
    }
    setLang(lang);
    setLoading(false);
  }, []);

  return loading ? null : (
    <div className={'App'}>
      <HeaderLeft setShowLogin={setShowLogin} setShowContact={setShowContact} showLogin={showLogin} showContact={showContact} logoColor={logoColor} />
      <HeaderRight setShowLogin={setShowLogin} setShowContact={setShowContact} showLogin={showLogin} showContact={showContact} />
      <SideBar />
      <Background />
      <div id="appContent" className={'ContentContainer'} ref={contentRef}>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/about" component={About} />
          <Route exact path="/team" component={Team} />
          <Route exact path="/community" component={Community} />
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/news" component={News} />
          <Route exact path="/partners" component={Partners} />
          <Route exact path="/contact-us" component={ContactUs} />
          <Route exact path="/products" component={Products} />
          <Route exact path="/stories/:storyID" component={Story} />
          <Route exact path="/news/:newsID" component={NewsDetails} />
        </Switch>
      </div>
      {showSearch && <Search />}
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    pathname: state.router.location.pathname,
    showSideMenu: state.app.showSideMenu,
    lang: state.app.lang,
    showSearch: state.app.showSearch,
    logoColor: state.app.logoColor,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLang: (lang) => dispatch(setLangAction(lang)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
