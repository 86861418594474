import { notification } from 'antd';

/**
 * 错误处理
 * @params error ResponseError
 * @params showErrorNotification boolean 是否弹出错误通知
 */
const errorHandler = ({ error, showErrorNotification = true }) => {
  const { status, url, message = 'unkown error', name = '' } = error;
  const errorText = `error - status:${status}, message:${message}, url:${url}`;
  // name === 'AbortError' -> 取消请求
  if (name === 'AbortError' || !showErrorNotification) {
    console.info(errorText);
    return;
  }
  console.error(errorText);
  notification.error({ message: `Http Error ${status}`, description: message });
};

export default errorHandler;
