import { isFinite } from 'lodash';
import { IMAGES_PREFIX, PDFS_PREFIX } from '../constants';

export const getOssImageUrl = (hashName, { width = 500, height } = {}) => {
  if (!hashName) {
    return '';
  }
  const query = [width, height].filter((k) => !!k).join('/');
  return `${IMAGES_PREFIX}/${hashName}${query ? `/${query}` : ''}`;
};

export const getOssImageRadio = (media) => {
  if (!media) {
    return 1;
  }
  const isVideo = !!media.source;
  const img = isVideo ? media?.cover : media;
  return isFinite(img?.width) && isFinite(img?.height) ? img?.width / img?.height : 1;
};

export const getOssPdfUrl = (hashName) => {
  if (!hashName) {
    return '';
  }
  return `${PDFS_PREFIX}/${hashName}`;
};
