import React, {useEffect, useRef, useState} from 'react';
import {withSize} from 'react-sizeme';
import {connect} from 'react-redux';
import AnimatedBackground from './AnimatedBackground';
import './Background.css';
import GradientBackground from './GradientBackground';

const Background = function ({size: {width, height}, pathname}) {
  const [played, setPlayed] = useState(false);
  const [stopped, setStopped] = useState(false);
  const audioElement = useRef();
  const playAudio = () => {
    if (!audioElement.current) {
      return;
    }
    const audioPromise = audioElement.current.play();
    if (audioPromise !== undefined) {
      audioPromise
        .then((_) => {
          // autoplay started
          setPlayed(true);
        })
        .catch((err) => {
          setPlayed(false);
        });
    }
  };
  const stopAudio = () => {
    if (!audioElement.current) {
      return;
    }
    audioElement.current.pause()
    setPlayed(false)
    setStopped(true)
  }
  return <div
    className={"Background"}
  >
    <AnimatedBackground
      width={width / 4}
      height={height / 4}
      pathname={pathname}
      onClick={() => {
        if (pathname === "/") {
          if (!played) {
            playAudio()
          } else if (!!played) {
            stopAudio()
          }
        }
      }}
    />
    {(pathname !== "/" && pathname !== "/login" && pathname !== "/contact-us") && <GradientBackground/>}
    {pathname === "/" && <audio loop={true} autoPlay={true} ref={audioElement}>
      <source src="/music.mp3" type="audio/mpeg"/>
    </audio>
    }
  </div>
}

const mapStateToProps = (state, ownProps) => {
  return {
    pathname: state.router.location.pathname,
    lang: state.app.lang,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(withSize({monitorHeight: true})(Background));
