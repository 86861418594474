import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { SIDE_MENU_STATUS_KEY } from '../constants';
import { toggleSideMenu, setSideMenu } from '../actions/app';
import { formatIntlMessage } from '../utils';
import './SideBar.css';

function SideBar({ showSideMenu, pathname, hash, logoColor, setSideMenu }) {
  const hoverWhite = pathname === '/' || pathname === '/login' || pathname === '/contact-us';

  useEffect(() => {
    const storageStatus = window.localStorage.getItem(SIDE_MENU_STATUS_KEY);
    setSideMenu(storageStatus === '1');
  }, []);

  return (
    <div className={`SideBar ${showSideMenu ? 'FullHeightMode' : 'IconMode'} ${hoverWhite ? 'HoverWhite' : ''}`}>
      <div className={`MenuContainer ${pathname === '/products' && logoColor === 'dark' ? 'Light' : 'Dark'}`}>
        {/*<div style={{paddingBottom: pathname === "/about"?22:17}} className={`${pathname === "/about"?"Active":""}`}>*/}
        <div className={`${pathname === '/products' ? `Active ${logoColor === 'dark' ? 'Light' : 'Dark'}` : ''}`}>
          <Link to={'/products'}>{formatIntlMessage('menu_products')}</Link>
        </div>
        <div style={{ paddingBottom: pathname === '/about' ? 17 + 0 : 10 }} className={`${pathname === '/about' ? 'Active' : ''}`}>
          <Link to={'/about'}>{formatIntlMessage('menu_about')}</Link>
          {pathname === '/about' && (
            <div className={'SubMenu'}>
              <div className={`${hash === '#about-mission-and-value' || hash === '' ? 'Active' : ''}`}>
                <a
                  href={'#about-mission-and-value'}
                  onClick={() => {
                    document.getElementById('Mission').scrollIntoView({
                      block: 'start',
                      inline: 'nearest',
                      behavior: 'smooth',
                    });
                  }}
                >
                  {formatIntlMessage('menu_about_mission_VISION')}
                </a>
              </div>
              <div className={`${hash === '#about-team-intro' ? 'Active' : ''}`}>
                <a
                  href={'#about-team-intro'}
                  onClick={() => {
                    document.getElementById('TeamOverview').scrollIntoView({
                      block: 'start',
                      inline: 'nearest',
                      behavior: 'smooth',
                    });
                  }}
                >
                  {formatIntlMessage('menu_about_team')}
                </a>
              </div>
            </div>
          )}
        </div>
        <div className={`${pathname === '/team' ? 'Active' : ''}`}>
          <Link to={'/team'}>{formatIntlMessage('menu_team')}</Link>
        </div>
        <div
          className={`${pathname === '/community' || /^\/stories\//.test(pathname) ? 'Active' : ''}`}
          style={{ paddingBottom: pathname === '/community' || /^\/stories\//.test(pathname) ? 17 + 0 : 10 }}
        >
          <Link to={'/community'}>{formatIntlMessage('menu_community')}</Link>
          {(pathname === '/community' || /^\/stories\//.test(pathname)) && (
            <div className={'SubMenu'}>
              <div className={`${pathname === '/community' && (hash === '#research' || hash === '') ? 'Active' : ''}`}>
                <a
                  href={pathname === '/community' ? '#research' : '/community#research'}
                  onClick={
                    pathname === '/community'
                      ? () => {
                          document.getElementById('research').scrollIntoView({
                            block: 'start',
                            inline: 'nearest',
                            behavior: 'smooth',
                          });
                        }
                      : undefined
                  }
                >
                  {formatIntlMessage('menu_community_research')}
                </a>
              </div>
              <div className={`${hash === '#stories' || /^\/stories\//.test(pathname) ? 'Active' : ''}`}>
                <a
                  href={pathname === '/community' ? '#stories' : '/community#stories'}
                  onClick={
                    pathname === '/community'
                      ? () => {
                          document.getElementById('stories').scrollIntoView({
                            block: 'start',
                            inline: 'nearest',
                            behavior: 'smooth',
                          });
                        }
                      : undefined
                  }
                >
                  {formatIntlMessage('menu_community_story')}
                </a>
              </div>
            </div>
          )}
        </div>
        <div className={`${pathname === '/contact' ? 'Active' : ''}`}>
          <Link to={'/contact'}>{formatIntlMessage('menu_contact')}</Link>
        </div>
        <div className={`${/^\/news/.test(pathname) ? 'Active' : ''}`}>
          <Link to={'/news'}>{formatIntlMessage('menu_news')}</Link>
        </div>
        <div className={`${pathname === '/partners' ? 'Active' : ''}`}>
          <Link to={'/partners'}>{formatIntlMessage('menu_partners')}</Link>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    pathname: state.router.location.pathname,
    hash: state.router.location.hash,
    showSideMenu: state.app.showSideMenu,
    logoColor: state.app.logoColor,
    lang: state.app.lang,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleSideMenu: () => dispatch(toggleSideMenu()),
    setSideMenu: (visible) => dispatch(setSideMenu(visible)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SideBar);
