import React from 'react';

export default function (props) {
  return (
    <svg width="15px" height="13px" viewBox="0 0 15 13" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-56.000000, -98.000000)" stroke="#6F6F6F" strokeWidth="1.5">
          <g transform="translate(56.000000, 99.000000)">
            <line x1="0" y1="0.5" x2="15" y2="0.5" />
            <line x1="0" y1="5.5" x2="15" y2="5.5" />
            <line x1="0" y1="10.5" x2="15" y2="10.5" />
          </g>
        </g>
      </g>
    </svg>
  );
}
