import * as actions from '../actions/app';

const initialState = {
  lang: 'cn',
  logoColor: 'dark',
  showSideMenu: false,
  showSearch: false,
};

const app = (state = initialState, action) => {
  switch (action.type) {
    case '@@router/LOCATION_CHANGE':
      return { ...state };
    // case actions.TOGGLE_SIDE_MENU:
    //   return { ...state, showSideMenu: !state.showSideMenu };
    case actions.SET_SIDE_MENU:
      return { ...state, showSideMenu: action.payload };
    case actions.TOGGLE_SEARCH:
      return { ...state, showSearch: !state.showSearch };
    case actions.SET_LOGO_COLOR:
      return { ...state, logoColor: action.payload };
    case actions.SET_LANG:
      return { ...state, lang: action.payload };
    default:
      return state;
  }
};

export default app;
