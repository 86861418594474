import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'abort-controller/polyfill';

/* scrollTo polyfill start */
if (!window.scrollTo) {
  window.scrollTo = function (x, y) {
    window.scrollLeft = x;
    window.scrollTop = y;
  };
}

if (!document.body.scrollTo) {
  Element.prototype.scrollTo = function (x, y) {
    this.scrollLeft = x;
    this.scrollTop = y;
  };
}
/* scrollTo polyfill end */
