import request from 'umi-request';
import AbortController from 'abort-controller';
import { setRequestTask, removeRequestTask } from './request-tasks';

const fetch = async (url, options) => {
  const { data = {}, requestTaskName, headers, ...restOptions } = options;

  const controller = new AbortController();
  const { signal } = controller;

  // 任务加入队列
  setRequestTask(requestTaskName, controller);

  const newOptions = {
    skipErrorHandler: true,
    signal,
    data,
    headers: {
      ...headers,
      // ...(isTokenHeader ? { Authorization: `Bearer ${getToken()}` } : {}),
    },
    timeout: 1000 * 15, // 默认超时15秒
    ...restOptions,
  };

  try {
    const result = await request(url, newOptions);
    // 任务从队列移除
    removeRequestTask(requestTaskName);
    return result;
  } catch (err) {
    // 避免 err.response 为 null 等情形
    if (!err.response) {
      err.response = {};
    }
    const { response: { status = '', statusText = '' } = {}, message = '', name = '' } = err;
    let errorText = message || statusText;
    try {
      const errorJSON = await err.response.json();
      if (errorJSON?.message) {
        errorText = errorJSON.message;
      }
    } catch (e) {
      // do nothing
    }
    const error = new Error(errorText);
    error.status = status;
    error.url = url;
    error.name = name;
    // err.name === 'AbortError' -> 请求取消
    if (!(err.name === 'AbortError')) {
      // 任务从队列移除
      removeRequestTask(requestTaskName);
    }

    throw error;
  }
};

export default fetch;
